.adyen-checkout__threeds2__challenge-container,
.adyen-checkout__threeds2__challenge {
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 400px;
  height: inherit; }

.adyen-checkout__threeds2__challenge--01 {
  width: 250px;
  height: 400px; }
  .adyen-checkout__threeds2__challenge--01 .adyen-checkout__iframe--threeDSIframe {
    width: 250px;
    height: 400px; }

.adyen-checkout__threeds2__challenge--02 {
  width: 390px;
  height: 400px; }
  .adyen-checkout__threeds2__challenge--02 .adyen-checkout__iframe--threeDSIframe {
    width: 390px;
    height: 400px; }

.adyen-checkout__threeds2__challenge--03 {
  width: 500px;
  height: 600px; }
  .adyen-checkout__threeds2__challenge--03 .adyen-checkout__iframe--threeDSIframe {
    width: 500px;
    height: 600px; }

.adyen-checkout__threeds2__challenge--04 {
  width: 600px;
  height: 400px; }
  .adyen-checkout__threeds2__challenge--04 .adyen-checkout__iframe--threeDSIframe {
    width: 600px;
    height: 400px; }

.adyen-checkout__threeds2__challenge--05 {
  width: 100%;
  height: 100%; }
  .adyen-checkout__threeds2__challenge--05 .adyen-checkout__iframe--threeDSIframe {
    width: 100%;
    height: 100%; }

.adyen-checkout__iframe--threeDSIframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0; }
