.adyen-checkout-ctp__loading-loader {
  padding-top: 58px;
  margin-bottom: 24px; }

.adyen-checkout-ctp__loading-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px; }

.adyen-checkout-ctp__loading-subtitle {
  font-size: 16px;
  line-height: 19px;
  max-width: 300px;
  text-align: center;
  margin: 0 auto 59px; }
