.adyen-checkout__paywithgoogle {
  height: 48px; }

.adyen-checkout__paywithgoogle > div > button, .adyen-checkout__paywithgoogle > div > button.long, .adyen-checkout__paywithgoogle > div > button.short {
  height: 48px;
  transition: background-color 0.3s ease-out, box-shadow 0.3s ease-out; }
  .adyen-checkout__paywithgoogle > div > button:focus, .adyen-checkout__paywithgoogle > div > button.long:focus, .adyen-checkout__paywithgoogle > div > button.short:focus {
    box-shadow: 0 0 0 2px #99c2ff;
    outline: 0; }

.adyen-checkout__paywithgoogle > div > button.gpay-button {
  padding: 15px 24px 13px; }
