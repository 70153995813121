.loading-input__form {
  opacity: 1; }

.loading-input__form--loading {
  opacity: 0; }

.loading-input__spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none; }

.loading-input__spinner--active {
  display: block; }
