.adyen-checkout__dropdown {
  position: relative; }

.adyen-checkout__dropdown__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between; }

.adyen-checkout__dropdown__button:after {
  position: absolute;
  content: '';
  height: 6px;
  right: 16px;
  width: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.19471 6.5646C3.59429 7.09797 4.39396 7.0986 4.79439 6.56587L7.78716 2.58424C8.28257 1.92514 7.81232 0.983398 6.98779 0.983398L1.01209 0.983398C0.188292 0.983398 -0.282154 1.92367 0.211778 2.58298L3.19471 6.5646Z' fill='%23687282'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center; }
  [dir='rtl'] .adyen-checkout__dropdown__button:after {
    left: 16px;
    right: auto; }

.adyen-checkout__dropdown__button--active:after {
  transform: rotate(180deg); }

.adyen-checkout__filter-input {
  background: #fff;
  border: 0;
  caret-color: #0075FF;
  color: #00112c;
  font-family: inherit;
  font-size: 1em;
  height: 100%;
  padding: 0;
  width: 100%; }
  .adyen-checkout__filter-input::placeholder {
    color: #b9c4c9;
    font-weight: 200; }
  .adyen-checkout__filter-input:focus, .adyen-checkout__filter-input:active {
    outline: 0; }

.adyen-checkout__dropdown__list {
  position: absolute;
  width: 100%;
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  margin-bottom: 50px;
  overflow-y: auto;
  display: none; }

.adyen-checkout__dropdown__list.adyen-checkout__dropdown__list--active {
  display: block; }

.adyen-checkout__dropdown__element {
  display: flex;
  justify-content: space-between;
  align-items: center; }
