.adyen-checkout__field-wrapper {
  display: flex;
  width: 100%; }

.adyen-checkout__field--20 {
  width: 20%; }

.adyen-checkout__field--30 {
  width: 30%; }

.adyen-checkout__field--40 {
  width: 40%; }

.adyen-checkout__field--50 {
  width: 50%; }

.adyen-checkout__field--60 {
  width: 60%; }

.adyen-checkout__field--70 {
  width: 70%; }

.adyen-checkout__field--80 {
  width: 80%; }

.adyen-checkout__field--col-70 {
  width: calc(70% - 8px); }

.adyen-checkout__field--col-30 {
  width: calc(30% - 8px); }

.adyen-checkout__field--col-50 {
  width: calc(50% - 8px); }

.adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
  margin-right: 8px; }
  [dir='rtl'] .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
    margin-right: 0;
    margin-left: 8px; }

.adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
  margin-left: 8px; }
  [dir='rtl'] .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
    margin-left: 0;
    margin-right: 8px; }

.adyen-checkout__field-wrapper:last-of-type > .adyen-checkout__field {
  margin-bottom: 0; }

.adyen-checkout__input {
  color: #00112c;
  caret-color: #0075FF;
  font-size: 1em;
  font-family: inherit;
  display: block;
  height: 40px;
  background: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 6px;
  padding: 5px 8px;
  position: relative;
  outline: none;
  width: 100%;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out; }

.adyen-checkout__input:hover {
  border-color: #99a3ad; }

.adyen-checkout__input:required {
  box-shadow: none; }

.adyen-checkout__input[readonly],
.adyen-checkout__input--disabled {
  background: #e6e9eb;
  border-color: #e6e9eb; }

.adyen-checkout__input--disabled:hover {
  border-color: #e6e9eb; }

.adyen-checkout__input-wrapper {
  position: relative;
  display: block; }

.adyen-checkout__input-wrapper--block {
  display: block; }

.adyen-checkout-input__inline-validation {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  right: 14px; }
  [dir='rtl'] .adyen-checkout-input__inline-validation {
    right: auto;
    left: 14px; }
  [dir='ltr'] .adyen-checkout-input__inline-validation {
    right: 14px;
    left: auto; }

.adyen-checkout-input__inline-validation--valid {
  color: #089A43; }

.adyen-checkout-input__inline-validation--invalid {
  color: #C12424; }

.adyen-checkout__input--valid {
  border-bottom-color: #089A43; }

.adyen-checkout__input--error,
.adyen-checkout__input--invalid,
.adyen-checkout__input--error:hover,
.adyen-checkout__input--invalid:hover {
  border-color: #C12424; }

.adyen-checkout__input::placeholder {
  color: #707070;
  font-weight: 200; }

.adyen-checkout__input--date {
  padding-right: 30px; }

.adyen-checkout__input:active,
.adyen-checkout__input:focus,
.adyen-checkout__input--focus,
.adyen-checkout__input:active:hover,
.adyen-checkout__input:focus:hover,
.adyen-checkout__input--focus:hover {
  border: 1px solid #0075FF;
  box-shadow: 0 0 0 2px #99c2ff; }

.adyen-checkout__input[readonly],
.adyen-checkout__input[readonly]:hover {
  background-color: #e6e9eb;
  border-color: transparent;
  color: #687282;
  cursor: default; }
