.adyen-checkout__content-separator {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #687282;
  white-space: nowrap;
  font-size: 13px;
  line-height: 19px; }
  .adyen-checkout__content-separator:after, .adyen-checkout__content-separator:before {
    content: "";
    display: block;
    background: #e6e9eb;
    width: 100%;
    height: 1px; }
  .adyen-checkout__content-separator:after {
    margin-left: 20px; }
  .adyen-checkout__content-separator:before {
    margin-right: 20px; }
