.adyen-checkout-ctp__card-list-single-card {
  border-radius: 6px;
  background-color: #f7f8f9;
  height: 40px;
  padding: 12px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px; }

.adyen-checkout-ctp__card-image {
  border-radius: 3px;
  margin-right: 8px; }
