.adyen-checkout-ctp__section-logout-button {
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  color: #0075FF;
  margin-left: auto;
  cursor: pointer; }

.adyen-checkout-ctp__section-logout-button--disabled {
  pointer-events: none;
  color: #687282; }
