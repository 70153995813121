.adyen-checkout__payment-method__disable-confirmation {
  background: #C12424;
  font-size: 0.81em;
  color: #fff;
  border-right: 1px solid #b82222;
  border-left: 1px solid #b82222;
  overflow: hidden;
  opacity: 0;
  margin: 0 -17px 0;
  max-height: 0;
  transition: opacity 0.15s ease-out, max-height 0.15s linear, margin-bottom 0.1s linear; }
  .adyen-checkout__payment-method__disable-confirmation.adyen-checkout__payment-method__disable-confirmation--open {
    max-height: 62px;
    opacity: 1;
    margin-bottom: 16px; }

.adyen-checkout__payment-method__disable-confirmation__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px; }

.adyen-checkout__payment-method__disable-confirmation__buttons {
  display: flex; }

.adyen-checkout__payment-method__disable-confirmation__button {
  background: #C12424;
  border: 1px solid transparent;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: block;
  height: auto;
  line-height: 14px;
  margin: 0 0 0 8px;
  padding: 8px;
  width: auto; }
  .adyen-checkout__payment-method__disable-confirmation__button:hover, .adyen-checkout__payment-method__disable-confirmation__button:hover:focus {
    box-shadow: none;
    background: #ac2020; }
  .adyen-checkout__payment-method__disable-confirmation__button:active, .adyen-checkout__payment-method__disable-confirmation__button:hover:active {
    background: #961c1c;
    box-shadow: none; }

.adyen-checkout__payment-method__disable-confirmation__button--remove,
.adyen-checkout__payment-method__disable-confirmation__button--remove:disabled {
  border-color: #fff; }

.adyen-checkout__payment-method__disable-confirmation__button--cancel,
.adyen-checkout__payment-method__disable-confirmation__button--cancel:disabled {
  border-color: transparent; }
