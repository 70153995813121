.adyen-checkout__segmented-control {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 4px 5px;
  background: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 6px; }
  .adyen-checkout__segmented-control--disabled {
    pointer-events: none; }
    .adyen-checkout__segmented-control--disabled > .adyen-checkout__segmented-control-segment {
      color: #8390A3; }
    .adyen-checkout__segmented-control--disabled > .adyen-checkout__segmented-control-segment--selected {
      background: #F3F6F9;
      border: 1.5px solid #8390A3; }

.adyen-checkout__segmented-control-segment {
  font-weight: 500;
  color: #0075FF;
  width: 100%;
  height: 40px;
  flex-grow: 1;
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  border: 0;
  text-align: center;
  transition: background 0.3s ease-out; }
  .adyen-checkout__segmented-control-segment:not(.adyen-checkout__segmented-control-segment--selected):hover {
    background-color: #f7f8f9; }
  .adyen-checkout__segmented-control-segment:active {
    background-color: #f7f8f9;
    border: 1.5px solid #687282; }
  .adyen-checkout__segmented-control-segment--selected {
    background: #E5F1FF;
    border: 1.5px solid #0075FF;
    color: #0075FF;
    font-weight: 700; }
