.adyen-checkout-ctp__section {
  position: relative;
  background-color: white;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding: 16px; }
  .adyen-checkout-ctp__section .adyen-checkout__fieldset {
    margin-bottom: 24px; }

.adyen-checkout-ctp__section-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 4px; }

.adyen-checkout-ctp__section-subtitle {
  font-size: 13px;
  font-weight: 400;
  color: #687282;
  line-height: 19px;
  margin-bottom: 16px; }

.adyen-checkout-ctp__section-header-logo {
  width: 24px;
  margin-right: 6px; }

.adyen-checkout-ctp__section-header-pipe {
  height: 15px;
  margin-right: 6px; }

.adyen-checkout-ctp__section-header {
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 16px; }

.adyen-checkout-ctp__section-header-scheme {
  object-fit: none;
  margin-right: 6px; }
  .adyen-checkout-ctp__section-header-scheme-mc {
    width: 27px; }
  .adyen-checkout-ctp__section-header-scheme-visa {
    width: 35px; }

.adyen-checkout-ctp__separator {
  color: #00112c;
  font-size: 13px;
  font-weight: 400; }
