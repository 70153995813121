.adyen-checkout__fieldset--personalDetails .adyen-checkout__field--gender .adyen-checkout__radio_group {
  display: flex; }

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group {
  display: flex;
  margin: 8px 0; }

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper {
  margin-right: 20px; }
  .adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper:last-child {
    margin: 0; }
